import '../../App.css'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Appconfig from '../../config/config'
import '../../../src/styles.css';
import CasinoBetPlace from '../../components/casinoBetPlace'
import CasinoVideo from 'components/casinoVideo';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../../redux/slice/betting/bettingSlice';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import CasinoOldResults from "components/casinoOldResults";
import { getCasinoResults } from "../../redux/slice/casinoResults/casinoResultsSlice";
import { getCasinoCards } from "../../redux/slice/casinoCards/casinoCardsSlice";


export default function BACCARAT_C() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector((state) => state?.userInfo?.userdata?.value); // get login user details
    const casioResults = useSelector((state) => state?.casinoResults?.casinoResults); // get casino results
    const casioCards = useSelector((state) => state?.casinoCards?.casinoCards); // get casino cards

    const [loadCasino, setLoadCasino] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false);
    // console.log('casinoData', casinoData);
    const [casinoEventId, setCasinoEventId] = useState('');
    const [casinoRoundId, setCasinoRoundId] = useState('');
    const [casinoCardRoundId, setCasinoCardRoundId] = useState('');
    const [selectedChip, setSelectedChip] = useState(null);
    const [chipStatus, setChipStatus] = useState(false);
    const [result, setResult] = useState(false);


    const chipsData = [
        { name: '100', value: '100', img: '/baccarat/chips1k.svg' },
        { name: '200', value: '200', img: '/baccarat/chips5.svg' },
        { name: '300', value: '300', img: '/baccarat/chips10.svg' },
        { name: '400', value: '400', img: '/baccarat/chips20.svg' },
        { name: '500', value: '500', img: '/baccarat/chips1k.svg' },
        { name: '600', value: '600', img: '/baccarat/chips1k.svg' },
        { name: '700', value: '700', img: '/baccarat/chips1k.svg' },
        { name: '1k', value: '1000', img: '/baccarat/chips1k.svg' }
    ];


    const [marketState, setMarketState] = useState([]);
    const [cardsState, setCardsState] = useState('');
    const [resultState, setResultState] = useState([]);
    const [casinoStatus, setCasinoStatus] = useState('');

    // console.log('casinoStatus', casinoStatus);

    const [winnerName, setWinnerName] = useState('');

    const [showBetsSlip, setShowBetsSlip] = useState(false);
    const [chips, setChips] = useState([]);
    const [placing, setPlacing] = useState(false);
    const [type, setType] = useState('back')
    const [selection_id, setSelection_id] = useState('')
    const [market_id, setMarket_id] = useState('')


    const [htmlId, setHtmlId] = useState('')
    const [BetPlaceData, setBetPlaceData] = useState({
        event_id: "",
        round_id: "",
        market_id: "",
        is_back: "1",
        price: "",
        is_fancy: "",
        selection_id: "",
        runner_name: "",
        market_name: "",
        PM_FANCY: false
    });
    const [ProfitValue, setProfitValue] = useState(0);
    const [lossValue, setLossValue] = useState(0);
    const [StakeValue, setStakeValue] = useState(0);
    const [size, setSize] = useState('')
    const [is_fancy, setIs_fancy] = useState(false)
    const [defaultStake, setDefaultStake] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [position, setCasinoPosition] = useState([])

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    useEffect(() => {
        setTimeout(() => {
            setLoadCasino(false);
        }, 1000)
    })
    const [seconds, setSeconds] = useState(45);
    useEffect(() => {
        if (seconds > 0) {
            const interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setSeconds(45);
        }
    }, [seconds]);

    useEffect(() => {
        if (result) {
            dispatch(setData(new Date().toISOString()));
        }
    }, [result])

    const preDefineData = [
        {
            "marketId": "912410081231",
            "marketName": "WINNER",
            "min": 100,
            "max": 100000,
            "index": 0,
            "runners": [
                {
                    "selectionId": "38371235",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 1.95
                            }
                        ]
                    }
                },
                {
                    "selectionId": "38847854",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 1.95
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "38371235": "PLAYER",
                "38847854": "BANKER"
            }
        },
        {
            "marketId": "912410081232",
            "marketName": "TIE",
            "min": 100,
            "max": 100000,
            "index": 1,
            "runners": [
                {
                    "selectionId": "39578955",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 8
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "39578955": "TIE"
            }
        },
        {
            "marketId": "912410081233",
            "marketName": "PAIR",
            "min": 100,
            "max": 100000,
            "index": 2,
            "runners": [
                {
                    "selectionId": "539764342",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 11
                            }
                        ]
                    }
                },
                {
                    "selectionId": "963223764",
                    "status": "SUSPEND",
                    "price": {
                        "back": [
                            {
                                "size": 500000,
                                "price": 11
                            }
                        ]
                    }
                }
            ],
            "runnersName": {
                "539764342": "PLAYER PAIR",
                "963223764": "BANKER PAIR"
            }
        }
    ];


    useEffect(() => {
        if (userInfo) {
            dispatch(getCasinoResults({}))
        }
    }, [])
    useEffect(() => {
        if (userInfo) {
          dispatch(getCasinoCards({}))
        }
      }, [])

    useEffect(() => {
        const casinowebsocket = new WebSocket("wss://kingsws.operator.buzz:9093/");
        if (casinowebsocket) {


            casinowebsocket.onopen = () => {
                console.log('WebSocket connection opened');
                let sendReq = {
                    'event_id': Appconfig.casino_type_id['baccarat'],
                }
                casinowebsocket.send(JSON.stringify({ action: 'JOIN', data: sendReq }));

                // Send a test message to the server (if applicable)
            };




            casinowebsocket.onmessage = function (message) {
                const casinos = JSON.parse(message?.data);

                if (casinos?.action == "MARKET_UPDATE" && casinos?.event_type == Appconfig.casino_type_id['baccarat']) {
                    let casinoss = casinos?.data;

                    if (casinoss) {

                        setResult(false);

                        if (casinoss?.skyPlaceBet.length > 0) {

                            if (casinoss?.skyPlaceBet[0]?.data.status) {
                                setCasinoStatus(casinoss?.skyPlaceBet[0]?.data.status);
                                if (casinoss?.skyPlaceBet[0]?.data?.marketArr && Array.isArray(casinoss?.skyPlaceBet[0]?.data?.marketArr)) {

                                    setMarketState(casinoss?.skyPlaceBet[0]?.data?.marketArr);
                                }
                                setCasinoEventId(casinoss?.skyPlaceBet[0]?.eventId)
                                setCasinoRoundId(casinoss?.skyPlaceBet[0]?.roundId)

                                if (casinoss?.skyPlaceBet[0]?.data.status == "SUSPEND") {
                                    setShowBetsSlip(false);
                                }
                            }
                        } else {
                            setMarketState(preDefineData);
                            setCasinoStatus("SUSPEND");
                        }


                        if (casinoss?.skyCardScan) {
                            if (casinoss?.skyCardScan[0]?.data?.cardsArr) {
                                setCardsState(casinoss?.skyCardScan[0].data?.cardsArr);
                                // setCasinoEventId(casinoss?.skyCardScan[0]?.eventId)
                                setCasinoCardRoundId(casinoss?.skyCardScan[0]?.roundId)

                            }
                        }

                        if (casinoss?.skyResultDeclared) {
                            if (casinoss?.skyPlaceBet[0]?.roundId == casinoss?.skyResultDeclared[0]?.roundId) {
                                setResultState(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                getWinnerName(casinoss?.skyResultDeclared[0]?.data?.resultsArr);
                                setTimeout(() => {
                                    setResult(true);
                                }, 4000)
                            } else {
                                setWinnerName('');
                            }
                        }

                    }

                }



            }



            casinowebsocket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            casinowebsocket.onclose = (event) => {
                console.log('WebSocket connection closed:', event);
            };


        }

    }, []);


    function getWinnerName(resultsArr) {
        // Find the market with the name "WINNER"
        const winnerMarket = resultsArr.find(market => market.marketName === "WINNER");

        if (!winnerMarket) {
            return "No winner market found";
        }

        // Find the runner ID associated with the "WINNER"
        const winnerRunnerId = Object.keys(winnerMarket.runners).find(runnerId => winnerMarket.runners[runnerId] === "WINNER");

        if (!winnerRunnerId) {
            return "No winner found";
        }

        // Get the winner name using the runner ID
        const winnerName = winnerMarket.runnersName[winnerRunnerId];

        setWinnerName(winnerName + ' Win');
    }



    useEffect(() => {
        if (userInfo) {
            getChips();
            getAuraCasinoPosition();
        }
    }, [])

    function getAuraCasinoPosition() {
        var data = JSON.stringify({
            user_id: userInfo?._id,
            event_type: Appconfig.casino_type_id["baccarat"],
        });

        var config = {
            method: "post",
            url: `${Appconfig.apiUrl}ledger/getAuraCasinoPosition`,
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                // console.log('response', response);

                if (response.data.result) {
                    let casinoPos = response.data.resultData;

                    if (casinoPos.length > 0) {
                        casinoPos.forEach((element) => {

                            //   if ((element.market_name == "WINNER" && element.event_type == "1004") || (element.market_name == "WINNER" && element.event_type == "1008")) {
                            //     var elements = document.getElementsByClassName(
                            //       `selection_position_${element.selection_id}`
                            //     );
                            //     // console.log('elements', elements);
                            //     for (var el of elements) {
                            //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                            //       el.style.color = element.total_pl > 0 ? "green" : "red";
                            //     }
                            //   } else {
                            var runners = document.getElementsByClassName("runners_" + element.market_id);

                            for (var item of runners) {
                                var selecid = item.getAttribute("data-id");

                                var elements = document.getElementsByClassName(
                                    `position_${element.market_id}_${selecid}`
                                );
                                // console.log('elements', elements);
                                for (var el of elements) {
                                    if (selecid == element.selection_id) {
                                        el.innerHTML = Math.abs(element.profit.toFixed(2));
                                        el.style.color = "green";
                                    } else {
                                        el.innerHTML = Math.abs(element.loss.toFixed(2));
                                        el.style.color = "red";
                                    }
                                }
                            }
                            //   }


                        });
                        setCasinoPosition(response.data.resultData);
                    }

                    // if (casinoPos.length > 0) {
                    //   casinoPos.forEach((element) => {
                    //     var elements = document.getElementsByClassName(
                    //       `selection_position_${element.selection_id}`
                    //     );
                    //     // console.log('elements', elements);
                    //     for (var el of elements) {
                    //       el.innerHTML = Math.abs(element.total_pl.toFixed(2));
                    //       el.style.color = element.total_pl > 0 ? "green" : "red";
                    //     }
                    //   });
                    //   setCasinoPosition(response.data.resultData);
                    // }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function getChips() {
        if (userInfo) {
            var data = JSON.stringify({
                user_id: userInfo._id,
            });
            var config = {
                method: "post",
                url: `${Appconfig.apiUrl}chips/getChips`,
                headers: {
                    "Content-Type": "application/json",
                },
                data: data,
            };
            axios(config)
                .then(function (response) {
                    try {
                        if (response.status) {
                            setChips(response.data)
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        else {
            navigate('/login')
        }
    }


    function SetPosition(stake, priceVal, market_id, is_back, selection_id, market_name) {
        priceVal = parseFloat(priceVal)
        var MarketId = market_id;
        var MId = MarketId;
        var selectionId = selection_id;
        var isback = is_back;
        stake = parseFloat(stake);
        let MatchMarketTypes = "";
        var runners = document.getElementsByClassName("runners_" + MId);
        // console.log('runners', runners);
        // return false;
        var tempRunners = "";
        for (var item of runners) {
            var selecid = item.getAttribute('data-id');
            var winloss = 0
            // winloss = parseFloat(item.value);
            var curr = 0;
            // console.log({
            //     'selecid': selecid,
            //     'selectionId': selectionId,
            //     'market_name': market_name,
            //     'priceVal': priceVal,
            //     'stake': stake,
            //     'winloss': winloss,
            //     'isback': isback,
            // });

            if (selectionId == selecid) {
                if (market_name == "WINNER") {
                    if (isback) {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + ((priceVal * stake) / 100);
                        // } else {
                        curr = winloss + ((priceVal * stake) - stake);
                        // }
                    } else {
                        // if (MatchMarketTypes == 'M') {
                        //     curr = winloss + (-1 * parseFloat((priceVal * stake) / 100));
                        // } else {
                        curr = winloss + (-1 * parseFloat((priceVal * stake) - stake));
                        // }
                    }
                } else {
                    curr = winloss + ((priceVal * stake) - stake);
                }

            } else {
                if (isback == 1) {
                    curr = winloss + (-1 * (stake));
                } else {
                    curr = winloss + stake;
                }
            }
            var currV = curr;

            var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);
            // console.log('elements', elements);
            for (var el of elements) {
                el.innerHTML = Math.abs(currV.toFixed(2));
                el.style.color = currV > 0 ? 'green' : 'red';
            }

        }
    }


    function calc(t_stake, priceVal, selection_id) {
        // var isfancy = BetPlaceData.is_fancy;
        priceVal = parseFloat(priceVal);
        t_stake = parseFloat(t_stake);
        var isback = BetPlaceData.is_back
        // if (!isfancy) {
        var pl = ((Number(priceVal) * Number(t_stake)) - Number(t_stake));
        console.log('priceVal', priceVal, t_stake);
        console.log('pl', pl);
        console.log('isback', isback);

        pl = parseFloat(pl.toFixed(2));
        if (isback) {
            setProfitValue(pl)
            setLossValue(t_stake)
        } else {
            setLossValue(pl)
            setProfitValue(t_stake)
        }
        // SetPosition(priceVal);
        // }
    }

    const placeStakeValue = (stake) => {
        if (String(stake).startsWith("NaN")) {
            stake = String(stake).replace("NaN", "");
        }
        setStakeValue(parseFloat(stake));
        // calc(stake, BetPlaceData.price, BetPlaceData.selection_id)
        SetPosition(stake, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);
    };

    const cancelBetSlip = () => {
        SetPosition(0, BetPlaceData.price, BetPlaceData.market_id, BetPlaceData.is_back, BetPlaceData.selection_id, BetPlaceData.market_name);

        setShowBetsSlip(false);
        setSelection_id('');
        setHtmlId('');
    }


    const handleOpenBetSlip = (
        event_id,
        round_id,
        market_id,
        is_back,
        size,
        is_fancy,
        selection_id,
        runner_name,
        htmlId,
        PM_FANCY = null,
        market_name
    ) => {
        setShowBetsSlip(true);
        setSelection_id(selection_id)
        setMarket_id(market_id)
        setType(is_back ? 'back' : 'lay')
        setHtmlId(htmlId)
        setSize(size)
        setIs_fancy(is_fancy)
        console.log({
            'event_id': event_id,
            'market_id': market_id,
            'is_back': is_back,
            'size': size,
            'is_fancy': is_fancy,
            'selection_id': selection_id,
            'runner_name': runner_name,
            'new_price': htmlId,
            'PM_FANCY': PM_FANCY,
            'market_name': market_name,
        })

        // console.log('yes ')

        let selectElements = document.getElementsByClassName('market_runners_' + market_id.replace('.', '') + '_' + selection_id);


        setProfitValue(0);
        setStakeValue(0)
        var priceNew = htmlId;

        setBetPlaceData({
            event_id: event_id,
            round_id: round_id,
            market_id: market_id,
            is_back: is_back ? 1 : 0,
            price: priceNew,
            is_fancy: is_fancy,
            selection_id: selection_id,
            runner_name: runner_name,
            market_name: market_name,
            PM_FANCY: PM_FANCY
        });

        // calc(StakeValue, priceNew, selection_id)
        // placeStakeValue(StakeValue);

        let priceVal = parseFloat(priceNew);
        let t_stake = parseFloat(StakeValue);
        var isback = is_back ? 1 : 0
        // if (!isfancy) {
        var pl = ((Number(priceVal) * Number(t_stake)) - Number(t_stake));
        console.log('priceVal', priceVal, t_stake);
        console.log('pl', pl);
        console.log('isback', isback);

        pl = parseFloat(pl.toFixed(2));
        let placeData = {};
        if (isback) {
            placeData = {
                event_id: event_id,
                round_id: round_id,
                market_id: market_id,
                is_back: is_back ? 1 : 0,
                price: priceNew,
                is_fancy: is_fancy,
                selection_id: selection_id,
                runner_name: runner_name,
                market_name: market_name,
                PM_FANCY: PM_FANCY,
                profit: pl,
                loss: t_stake
            }


            // setProfitValue(pl)
            // setLossValue(t_stake)
        } else {

            placeData = {
                event_id: event_id,
                round_id: round_id,
                market_id: market_id,
                is_back: is_back ? 1 : 0,
                price: priceNew,
                is_fancy: is_fancy,
                selection_id: selection_id,
                runner_name: runner_name,
                market_name: market_name,
                PM_FANCY: PM_FANCY,
                profit: t_stake,
                loss: pl
            }

            // setLossValue(pl)
            // setProfitValue(t_stake)
        }

        // setTimeout(() => {
        betPlace(placeData);
        // }, 1000)

    }




    function betPlace(betData) {

        if (userInfo) {
            if (userInfo.user_type == "User") {


                if (StakeValue > 99) {

                    setPlacing(true);
                    setIsLoading(true);


                    var data = JSON.stringify({
                        user_id: userInfo._id,
                        match_id: betData.event_id,
                        round_id: betData.round_id,
                        selection_id: betData.selection_id,
                        is_back: betData.is_back,
                        stake: StakeValue,
                        price_val: betData.price,
                        market_id: betData.market_id,
                        is_fancy: "No",
                        market_name: betData.market_name,
                        runner_name: betData.runner_name,
                        event_name: "Baccarat",
                        profit: betData.profit,
                        loss: betData.loss,
                        pm_fancy: betData.PM_FANCY,
                        event_type: Appconfig.casino_type_id['baccarat'],
                        is_casino: 'Yes'
                    });

                    // console.log('data', JSON.parse(data));
                    // return false;

                    var config = {
                        method: "post",
                        url: `${Appconfig.apiUrl}betting/auraAddBetting`,
                        headers: {
                            "Content-Type": "application/json",
                        },
                        data: data,
                    };
                    // alert("success");
                    axios(config)
                        .then(function (response) {

                            dispatch(setData(new Date().toISOString()));

                            try {
                                setChipStatus(false);
                                setPlacing(false);
                                setSelection_id('')
                                setHtmlId('')
                                setProfitValue(0);
                                setStakeValue(0);
                                setShowBetsSlip(false)


                                if (response.data.result == 0) {
                                    // console.log('response:', response.data.resultMessage)
                                    NotificationManager.error(response.data.resultMessage, '', 3000);
                                } else {
                                    NotificationManager.success(response.data.resultMessage, '', 3000);

                                }
                                var MId = BetPlaceData.market_id;
                                var selectionId = BetPlaceData.selection_id;
                                var runners = document.getElementsByClassName("runners_" + MId);


                                for (var item of runners) {
                                    var selecid = item.getAttribute('data-id');

                                    var elements = document.getElementsByClassName(`position_${MId}_${selecid}`);

                                    for (var el of elements) {
                                        el.innerHTML = '';
                                    }

                                }

                                setBetPlaceData({
                                    event_id: "",
                                    round_id: "",
                                    market_id: "",
                                    is_back: "",
                                    price: "",
                                    is_fancy: "",
                                    selection_id: "",
                                    runner_name: "",
                                    market_name: "",
                                });
                                getAuraCasinoPosition();
                            } catch (e) {
                                console.log(e.message)
                            }
                            setIsLoading(false);

                        })
                        .catch(function (error) {
                            console.log(error);
                            console.log(error.message)
                            setIsLoading(true);

                        });
                }
                else {
                    NotificationManager.error('Min Stack Value Is 100', '', 3000);
                }

            }
        }
        else {
            navigate('/login')
        }
    }

    const CustomContent = ({ market }) => {
        return (
            <span className='lg:block text-[.75rem] font-bold text-[#ffffff]'>
                Min/Max: {market?.min + ' - ' + market?.max}
            </span>
        );
    };

    const handleClick = (value, status) => {
        setSelectedChip(value);
        setChipStatus(status)
        if (status) {
            console.log('value', value, status);
            placeStakeValue(value)
        }
    };


    return (
        <>
            {loadCasino ?
                <div className='block absolute w-full left-0 top-0 h-screen overflow-hidden'>
                    <div className='fixed top-0 relative w-full h-screen flex justify-center items-center bg-[#000000] z-[99999]'>
                        <img src='/other/loader.gif' className='w-[350px]' />
                    </div>
                </div>
                :
                <div className='grid grid-cols-12 relative top-[56px] lg:top-0'>
                    <div className='col-span-12 relative flex'>
                        <CasinoVideo />
                        <div className='absolute bg-[rgba(0,_0,_0,_.4)] h-auto left-[0] content-[""] p-[3px]'>
                            <div className='flex flex-col'>
                                <div className="font-bold text-[#fff] !text-[10px]">RID: {casinoRoundId}</div>

                                <div className='flex flex-col'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>PLAYER</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER?.card_1 : '0'}.png`}></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER?.card_2 : '0'}.png`}></img>
                                        {
                                            casinoCardRoundId == casinoRoundId && cardsState?.PLAYER?.card_3 != '0' &&
                                            <img className='w-[23px] h-auto ml-[3px] rotate-90' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.PLAYER?.card_3 : '0'}.png`}></img>
                                        }


                                    </div>
                                </div>
                                <div className='flex flex-col pt-[3px]'>
                                    <span className='leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]'>BANKAR</span>
                                    <div className='flex'>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.BANKER?.card_1 : '0'}.png`}></img>
                                        <img className='w-[23px] h-auto mr-[3px]' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.BANKER?.card_2 : '0'}.png`}></img>
                                        {
                                            casinoCardRoundId == casinoRoundId && cardsState?.BANKER?.card_3 != '0' &&
                                            <img className='w-[23px] h-auto ml-[3px] rotate-90' src={`https://casino.kingexch9.com/assets/cards/${cardsState && casinoStatus == "SUSPEND" && casinoCardRoundId == casinoRoundId ? cardsState?.BANKER?.card_3 : '0'}.png`}></img>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            winnerName &&
                            <div className="winnerOverlay absolute h-auto bottom-2/4 ml-auto mr-auto p-[5px] w-full flex justify-center">
                                <div className="mini-result-new active border-[0] border-[solid] mb-0 text-[#000] bg-[linear-gradient(-180deg,_#fd8f3b_0%,_#fd3523_100%)] w-auto text-[14px] p-[6px] uppercase rounded-tl-[16px] rounded-br-[16px] rounded-tr-[2px] rounded-bl-[2px] min-h-[20px] [transition:all_.5s_cubic-bezier(.168,-.885,.3,2)_.5s] scale-0 scale-100">
                                    <span> {winnerName}</span>
                                </div>
                                <span className='absolute' >
                                    <canvas width="100vw" height="100vh">
                                    </canvas>
                                </span>
                            </div>
                        }




                        <div className="timer-overlay  ">
                            <div className='setting-btn text-white text-[1rem]'>
                                <i className="fa fa-cog fa-lg" style={{ color: '#ff8100' }}></i>
                            </div>
                            <div className="timer-group">
                                <div className="timer minute">
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                    <div className="hand">
                                        <span className="">
                                        </span>
                                    </div>
                                </div>
                                <div className="face">
                                    <p id="lazy" className="">{seconds}</p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-span-12 h-[calc(101vh-40vh)] lg:h-auto overflow-auto'>
                        <div className='grid-cols-12'>

                            {
                                marketState?.length > 0 && marketState?.map((market, i) => (
                                    <div key={i} className="baccarat">
                                        {/* First row for PLAYER, TIE, BANKER */}
                                        {(market.marketName === "WINNER" || market.marketName === "TIE") && (
                                            <div className="baccarat-bets flex">
                                                {market.runners.map((runner, j) => {
                                                    const runnerName = market.runnersName[runner.selectionId];
                                                    const price = runner?.price?.back[0]?.price || '-';
                                                    const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended
                                                    return (
                                                        <div key={j} data-id={runner.selectionId} className={`runner-box runners_${market.marketId} ${runnerName.toLowerCase()} ${isSuspended ? 'suspended' : ''}`}>
                                                            <div className="baccarat-bets-name" onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                casinoEventId,
                                                                casinoRoundId,
                                                                market.marketId,
                                                                true,
                                                                runner?.price?.back[0]?.size,
                                                                false,
                                                                runner.selectionId,
                                                                market?.runnersName[runner?.selectionId],
                                                                runner?.price?.back[0]?.price,
                                                                false,
                                                                market.marketName
                                                            ) : null} >
                                                                <div>{runnerName}</div>
                                                                <div>{price}</div>

                                                                <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}></div>

                                                                <li className="chips_1_selected" style={{ display: 'none' }}>
                                                                    <p></p>
                                                                </li>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}

                                        {/* Second row for PLAYER PAIR and BANKER PAIR */}
                                        {market.marketName === "PAIR" && (
                                            <div className="baccarat mt-3">
                                                <div className="baccarat-bets flex justify-content-between">
                                                    {market.runners.map((runner, k) => {
                                                        const runnerName = market.runnersName[runner.selectionId];
                                                        const price = runner?.price?.back[0]?.price || '-';
                                                        const isSuspended = runner.status === "SUSPEND"; // Check if status is suspended
                                                        return (
                                                            <div key={k} className={`pair-box ${runnerName.toLowerCase().replace(' ', '-')} ${isSuspended ? 'suspended' : ''}`}>
                                                                <div className="baccarat-bets-name" onClick={!isSuspended ? () => handleOpenBetSlip(
                                                                    casinoEventId,
                                                                    casinoRoundId,
                                                                    market.marketId,
                                                                    true,
                                                                    runner?.price?.back[0]?.size,
                                                                    false,
                                                                    runner.selectionId,
                                                                    market?.runnersName[runner?.selectionId],
                                                                    runner?.price?.back[0]?.price,
                                                                    false,
                                                                    market.marketName
                                                                ) : null}>
                                                                    <div>{runnerName}</div>
                                                                    <div>{price}</div>
                                                                    <div className={`text-center align-items-center p-0 position_${market.marketId}_${runner.selectionId} selection_position_${runner.selectionId}`}>0</div>
                                                                    <li className="chips_1_selected" style={{ display: 'none' }}>
                                                                        <p></p>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))
                            }




                            <section id="functionZone" className="function_zone mt-5">
                                <ul id="chips" className="chips">
                                    {chipsData.map((chip) => (
                                        <li
                                            key={chip.value}
                                            className={`chips_1 ${selectedChip === chip.value && chipStatus ? 'now' : ''}`}
                                            onClick={() => handleClick(chip.value, chipStatus ? false : true)}
                                        >
                                            <img src={chip.img} alt={`${chip.name} chip`} />
                                            <p>{chip.name}</p>
                                            <span className="ring">ring</span>
                                        </li>
                                    ))}
                                </ul>
                            </section>


                        </div>
                    </div>

                    {
                        (casioResults && casioResults?.baccaratResultDeclared) &&
                        <CasinoOldResults event_type={Appconfig.casino_type_id["baccarat"]} results={casioResults?.baccaratResultDeclared} cards={casioCards.baccaratCardDeclared} />
                    }
                </div >
            }
        </>
    )
}
// export default Casino;
