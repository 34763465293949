import React from 'react'
import '../../App.css'
import '../../../src/styles.css';
import Appconfig from '../../config/config'

const INSTANT_WORLI = () => {
  return (
    <>

        <div className="col-span-12 relative flex">
            <video className="casinovideo" id="remoteVideo" width="100%" height="100%"
                autoplay="" playsinline="" style={{backgroundsize: 'contain'}}>
                </video>
            <div className="new-video">
                <div className="flex flex-col">
                    <div className="font-bold text-[#fff] !text-[10px]">RID: 9180712081143</div>
                    <div className="flex flex-col"><span
                            className="leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]">DRAGON</span>
                        <div className="flex"><img className="w-[23px] h-auto mr-[3px]"
                                src="https://casino.kingexch9.com/assets/cards/H4_.png" /></div>
                    </div>
                    <div className="flex flex-col pt-[3px]"><span
                            className="leading-[9px] font-bold mb-0 mt-0 uppercase w-full !text-[12.5px] pb-[3px] pt-[3px] text-[#ffffff]">TIGER</span>
                        <div className="flex"><img className="w-[23px] h-auto mr-[3px]"
                                src="https://casino.kingexch9.com/assets/cards/0.png" /></div>
                    </div>
                </div>
            </div>
            <div className="timer-overlay  ">
                <div className="setting-btn text-white text-[1rem]"><i className="fa fa-cog fa-lg" style={{color: 'rgb(255, 129, 0)'}}></i>
                </div>
                <div className="timer-group">
                    <div className="timer minute">
                        <div className="hand"><span className=""></span></div>
                        <div className="hand"><span className=""></span></div>
                    </div>
                    <div className="face">
                        <p id="lazy" className="">35</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="scrollMarket">
            <div className="card">
                <span data-bs-toggle="collapse" data-bs-target="#multiCollapseExample1" role="button"
                    aria-expanded="true" aria-controls="multiCollapseExample1"
                    className="btn btn-primary card-header inner-sportbook">
                    <span><strong>WINNER</strong></span>
                    <span></span>
                </span>
                <div className="col">
                    <div id="multiCollapseExample1" className="show">
                        <table className="table table-hover tbl-bets p-rltv sportbook-market-tbl">
                            {/* <div className="suspended-casino"><span className="stats-text">Suspended</span></div> */}
                            <tr>
                                <th colspan="" className="min-max-mobile p-1">
                                    <dl id="minMaxBox" className="fancy-info">
                                        <dt>Min/Max &nbsp;</dt>
                                        <dd id="minMaxInfo"> 100 - 500000 </dd>
                                    </dl>
                                </th>
                            </tr>
                            <div className="casino-table">
                                <div className="tab-content">
                                    <div className="p-0">
                                        <div className="worlibox">
                                            <div className="worli-left">
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 1
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 2
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 3
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 4
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 5
                                                        </span></span></div>

                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 6
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 7
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 8
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 9
                                                        </span></span></div>
                                                <div className="worli-odd-box back gy-1"><span><span className="worli-odd"> 0
                                                        </span></span></div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card"><span data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" role="button"
                    aria-expanded="true" aria-controls="multiCollapseExample2"
                    className="btn btn-primary card-header inner-sportbook"><span><strong>LINE</strong></span><span></span></span>
                <div className="col">
                    <div id="multiCollapseExample2" className="collapse multi-collapse show">
                        <table className="table table-hover tbl-bets p-rltv sportbook-market-tbl">
                            {/* <div className="suspended-casino"><span className="stats-text">Suspended</span></div> */}
                            <tr>
                                <th colspan="" className="min-max-mobile p-1">
                                    <dl id="minMaxBox" className="fancy-info">
                                        <dt>Min/Max &nbsp;</dt>
                                        <dd id="minMaxInfo"> 100 - 100000 </dd>
                                    </dl>
                                </th>
                            </tr>
                            <tr>
                                <div className="casino-table">
                                    <div className="tab-content p-0">
                                        <div className="p-0">
                                            <div className="worlibox">
                                                <div className="worli-right">
                                                    <div className="worli-box-row1">
                                                        <div className="worli-odd-box back spn" style={{height: '85px'}}><span
                                                                className="d-flex flex-column"><span className="worli-odd">LINE
                                                                    1</span><span className="d-block">1|2|3|4|5</span></span>
                                                        </div>
                                                        <div className="worli-odd-box back spn" style={{height: '85px'}}><span
                                                                className="d-flex flex-column"><span className="worli-odd">LINE
                                                                    2</span><span className="d-block">6|7|8|9|0</span></span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tr>
                            <tr></tr>
                        </table>
                    </div>
                </div>
            </div>
            <div className="card"><span data-bs-toggle="collapse" data-bs-target="#multiCollapseExample2" role="button"
                    aria-expanded="true" aria-controls="multiCollapseExample2"
                    className="btn btn-primary card-header inner-sportbook"><span><strong>ODD/EVEN</strong></span><span></span></span>
                <div className="col">
                    <div id="multiCollapseExample2" className="collapse multi-collapse show">
                        <table className="table table-hover tbl-bets p-rltv sportbook-market-tbl">
                            {/* <div className="suspended-casino"><span className="stats-text">Suspended</span></div> */}
                            <tr>
                                <th colspan="" className="min-max-mobile p-1">
                                    <dl id="minMaxBox" className="fancy-info">
                                        <dt>Min/Max &nbsp;</dt>
                                        <dd id="minMaxInfo"> 100 - 100000 </dd>
                                    </dl>
                                </th>
                            </tr>
                            <tr>
                                <div className="casino-table">
                                    <div className="tab-content p-0">
                                        <div className="p-0">
                                            <div className="worlibox">
                                                <div className="worli-right">
                                                    <div className="worli-box-row1">
                                                        <div className="worli-odd-box back spn" style={{height: '90px'}}><span
                                                                className="d-flex flex-column"><span
                                                                    className="worli-odd">ODD</span><span
                                                                    className="d-block">1|3|5|7|9</span></span>
                                                        </div>
                                                        <div className="worli-odd-box back spn" style={{height: '90px'}}><span
                                                                className="d-flex flex-column"><span
                                                                    className="worli-odd">EVEN</span><span
                                                                    className="d-block">0|2|4|6|8</span></span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tr>
                            <tr></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>    

    </>
  )
}

export default INSTANT_WORLI