import '../../App.css'
import { Modal } from "antd";
import { useEffect, useState } from "react";
import CasinoResultModal from "components/casinoResultModal";


function CasinoOldResults(props) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [teenpattiResult, setTeenpattiResult] = useState([]);
    const [resultRound, setResultRound] = useState({});
    const [cardRound, setCardRound] = useState({});
    const [winner, setWinner] = useState('');


    useEffect(() => {
        if (props.event_type == "1004" || props.event_type == "1009" || props.event_type == "1011") {
            if (props.results) {
                let result = props.results;
                let cards = props.cards;
                result?.map(res => {
                    cards?.map(card => {
                        let cardRoundId = card[0].roundId;
                        if (cardRoundId == res[0].roundId) {
                            let findRes = res[0]?.data?.resultsArr[0];
                            if (findRes.marketName == "WINNER") {
                                const winnerRunnerId = Object.keys(findRes.runners).find(
                                    (runnerId) => findRes.runners[runnerId] === "WINNER"
                                );
                                const winnerName = findRes.runnersName[winnerRunnerId];
                                setTeenpattiResult(prevResults => {
                                    const newResult = {
                                        'round_id': res[0].roundId,
                                        'winner': winnerName === "PLAYER A" ? 'A' : 'B',
                                        'roundResult': res[0],
                                        'roundCard': card[0]
                                    };
                                    return prevResults.some(r => r.round_id === newResult.round_id)
                                        ? prevResults
                                        : [...prevResults, newResult];
                                });
                            }
                        }
                    })
                })
            }
        } else if (props.event_type == "1008") {
            if (props.results) {
                let result = props.results;
                let cards = props.cards;

                result?.map(res => {
                    cards?.map(card => {
                        let cardRoundId = card[0].roundId;
                        if (cardRoundId == res[0].roundId) {
                            let findRes = res[0]?.data?.resultsArr[0];
                            if (findRes.marketName == "WINNER") {
                                const winnerRunnerId = Object.keys(findRes.runners).find(
                                    (runnerId) => findRes.runners[runnerId] === "WINNER"
                                );
                                const winnerName = findRes.runnersName[winnerRunnerId];
                                setTeenpattiResult(prevResults => {
                                    const newResult = {
                                        'round_id': res[0].roundId,
                                        'winner': winnerName === "LOW CARD ( A to 6 )" ? 'L' : 'H',
                                        'roundResult': res[0],
                                        'roundCard': card[0]
                                    };
                                    return prevResults.some(r => r.round_id === newResult.round_id)
                                        ? prevResults
                                        : [...prevResults, newResult];
                                });
                            }
                        }
                    })
                })
            }
        } else if (props.event_type == "1005" || props.event_type == "1012" || props.event_type == "1013" || props.event_type == "1017") {
            if (props.results) {
                let result = props.results;
                let cards = props.cards;
                result?.map(res => {
                    cards?.map(card => {
                        let cardRoundId = card[0].roundId;
                        if (cardRoundId == res[0].roundId) {
                            let findRes = res[0]?.data?.resultsArr[0];
                            if (findRes.marketName == "WINNER") {
                                const winnerRunnerId = Object.keys(findRes.runners).find(
                                    (runnerId) => findRes.runners[runnerId] === "WINNER"
                                );
                                const winnerName = findRes.runnersName[winnerRunnerId];
                                setTeenpattiResult(prevResults => {
                                    const newResult = {
                                        'round_id': res[0].roundId,
                                        'winner': winnerName === "DRAGON" ? 'D' : 'T',
                                        'roundResult': res[0],
                                        'roundCard': card[0]
                                    };
                                    return prevResults.some(r => r.round_id === newResult.round_id)
                                        ? prevResults
                                        : [...prevResults, newResult];
                                });
                            }
                        }
                    })
                })
            }
        } else if (props.event_type == "1002") {
            if (props.results) {
                let result = props.results;
                let cards = props.cards;

                result?.map(res => {
                    cards?.map(card => {
                        let cardRoundId = card[0].roundId;
                        if (cardRoundId == res[0].roundId) {
                            let findRes = res[0]?.data?.resultsArr[0];
                            if (findRes.marketName == "WINNER") {
                                const winnerRunnerId = Object.keys(findRes.runners).find(
                                    (runnerId) => findRes.runners[runnerId] === "WINNER"
                                );
                                const winnerName = findRes.runnersName[winnerRunnerId];
                                setTeenpattiResult(prevResults => {
                                    const newResult = {
                                        'round_id': res[0].roundId,
                                        'winner': ['1st BET A', '2nd BET A'].includes(winnerName) ? 'A' : 'B',
                                        'roundResult': res[0],
                                        'roundCard': card[0]
                                    };
                                    return prevResults.some(r => r.round_id === newResult.round_id)
                                        ? prevResults
                                        : [...prevResults, newResult];
                                });
                            }
                        }
                    })
                })
            }
        } else if (props.event_type == "1007") {
            if (props.results) {
                let result = props.results;
                let cards = props.cards;
                result?.map(res => {
                    cards?.map(card => {
                        let cardRoundId = card[0].roundId;
                        if (cardRoundId == res[0].roundId) {
                            let findRes = res[0]?.data?.resultsArr[0];
                            if (findRes.marketName == "WINNER") {
                                const winnerRunnerId = Object.keys(findRes.runners).find(
                                    (runnerId) => findRes.runners[runnerId] === "WINNER"
                                );
                                const winnerName = findRes.runnersName[winnerRunnerId];
                                setTeenpattiResult(prevResults => {
                                    const newResult = {
                                        'round_id': res[0].roundId,
                                        'winner': ['Player 8'].includes(winnerName) ? '8' : ['Player 9'].includes(winnerName) ? '9' : ['Player 10'].includes(winnerName) ? '10' : '11',
                                        'roundResult': res[0],
                                        'roundCard': card[0]
                                    };
                                    return prevResults.some(r => r.round_id === newResult.round_id)
                                        ? prevResults
                                        : [...prevResults, newResult];
                                });
                            }
                        }
                    })
                })
            }
        } else if (props.event_type == "1010") {
            if (props.results) {
                let result = props.results;
                let cards = props.cards;

                result?.map(res => {
                    cards?.map(card => {
                        let cardRoundId = card[0].roundId;
                        if (cardRoundId == res[0].roundId) {
                            let findRes = res[0]?.data?.resultsArr[0];
                            if (findRes.marketName == "WINNER") {
                                const winnerRunnerId = Object.keys(findRes.runners).find(
                                    (runnerId) => findRes.runners[runnerId] === "WINNER"
                                );
                                const winnerName = findRes.runnersName[winnerRunnerId];
                                setTeenpattiResult(prevResults => {
                                    const newResult = {
                                        'round_id': res[0].roundId,
                                        'winner': ['PLAYER'].includes(winnerName) ? 'P' : 'B',
                                        'roundResult': res[0],
                                        'roundCard': card[0]
                                    };
                                    return prevResults.some(r => r.round_id === newResult.round_id)
                                        ? prevResults
                                        : [...prevResults, newResult];
                                });
                            }
                        }
                    })
                })
            }
        }
    }, [])

    const handleCancel = () => {
        setIsModalOpen(false);;
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    return (
        <>
            <div className="col-span-12 mt-[5px] bg-[#000] p-[5px] text-[#fff] inline-block overflow-x-auto mb-[100px] whitespace-nowrap">
                <h5 className="inline-block font-black text-[1.07375rem] m-0">
                    Recent Result
                </h5>
                <ul className="inline-block whitespace-nowrap">
                    {
                        teenpattiResult.length > 0 ?
                            teenpattiResult?.map(teen => {
                                return (
                                    <li className={`w-[35px] text-[#333] ml-[10px] p-[5px] [list-style:none] text-center font-black inline-block rounded-[15px] 
                                       ${['A', 'H', 'D', 'P'].includes(teen.winner) ? 'bg-[#72bbef]' : ['8', '9', '10', '11'].includes(teen.winner) ? 'bg-[#ffff33]' : 'bg-[#f9a9ba]'} `}>
                                        <button
                                            type="button"
                                            className="text-[#333]"
                                            onClick={() => {
                                                setResultRound(teen.roundResult)
                                                setCardRound(teen.roundCard)
                                                setWinner(teen.winner)
                                                showModal()
                                            }
                                            }
                                        >
                                            {teen.winner}
                                        </button>
                                    </li>
                                )
                            })

                            :
                            <></>
                    }

                </ul>

                <CasinoResultModal event_type={props.event_type} resultRound={resultRound} cardRound={cardRound} winner={winner} isModalOpen={isModalOpen} handleCancel={handleCancel} showModal={showModal} />

            </div>
        </>
    )
}
export default CasinoOldResults;
